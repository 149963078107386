.title-block {
  margin-bottom: 15px;
  h1 {
    margin-bottom: 15px;
  }
  .subtitle {
    font-weight: bold;
  }
}
.mat-card {
  padding: 0;
}

.w100, .w-100 {
  width: 100%;
}

.invisible-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.widget-tooltip {
  font-size: 16px;
  background: #777777cc;
}