/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import './styles/main-components.scss';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

h1, h2, h3, h4, h5, h6, p {
  padding: 0;
  margin: 0;
}
